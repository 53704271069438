jQuery(function($) {
  smoothScroll.init({
    speed: 1000, // Integer. How fast to complete the scroll in milliseconds
    easing: "easeInOutQuad", // Easing pattern to use
    updateURL: true, // Boolean. Whether or not to update the URL with the anchor hash on scroll
    offset: 97, // Integer. How far to offset the scrolling anchor location in pixels
    callbackBefore: function(toggle, anchor) {}, // Function to run before scrolling
    callbackAfter: function(toggle, anchor) {} // Function to run after scrolling
  });
});

$(window).on("load", function() {
  var hash = location.hash;

  if (hash.length > 0) {
    var x = $(hash).offset().top - $("#navbar").height() + 30;
    $("html,body").animate({ scrollTop: x }, 1000);
  }
});
